<template>
  <div class="pc-page-content">
    <div class="pc-page-header">
      <p>分配权限</p>
    </div>
    <el-form :inline="true" :model="roleForm" ref="roleForm" label-width="100px">
      <el-form-item label="角色名称">
        <el-input v-model="roleForm.menuName" clearable class="pc-input-readonly-color pc-input-col3" :readonly="true"></el-input>
      </el-form-item>
      <el-tree
        ref="tree"
        :data="treeData"
        show-checkbox
        node-key="id"
        :default-expanded-keys="defaultExpandedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :props="defaultProps"
      >
      </el-tree>
      <el-form-item label=" " style="margin-top: 20px">
        <el-button type="primary" @click="submit">保 存</el-button>
        <el-button @click="goBack">返 回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { erpConfirm, errorMessage } from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default {
  data() {
    return {
      roleForm: this.$route.params,
      treeData: [],
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'subMenu',
        label: 'menuName',
      },
    };
  },
  created() {
    this.initAuthTree();
  },
  methods: {
    initAuthTree() {
      this.ajax({
        url: `/uac/position/getActionTreeByPositionId/${this.roleForm.id}`,
        loading: true,
        success: (res) => {
          if (res.code === 500) {
            this.goBack();
          }
          const data = res.result;
          if (!data) {
            return;
          }
          this.treeData = data.authTree;
          this.defaultCheckedKeys = data.checkedAuthList;
          this.defaultExpandedKeys.push(this.treeData[0].id);
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    submit() {
      const confirmMsg = '确定保存' + this.roleForm.menuName + '的权限吗?';
      const param = {};
      const actionIdList = [];
      const menuIdList = [];
      param.positionId = this.roleForm.id;
      const checkedNodeList = this.$refs.tree.getCheckedNodes(true);
      checkedNodeList.forEach((item) => {
        if (item.remark === 'menu') {
          menuIdList.push(item.id);
        } else {
          actionIdList.push(item.id);
        }
      });
      param.actionIdList = actionIdList;
      param.menuIdList = menuIdList;
      erpConfirm(confirmMsg).then(async () => {
        const res = await httpPost('/uac/position/bindAction', param);
        if (res.code === 200) {
          this.goBack();
        } else {
          errorMessage(res.message);
        }
      });
    },
  },
};
</script>
